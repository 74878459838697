.youtubeembedroot {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625) */
    height: 0; /* Altezza gestita dal padding */
    overflow: hidden;

    & iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.paymentcontributeicon {
    width: 120px;
    height: 100%;
    fill: var(--mantine-color-tertiary-6);
    animation: heartbeat 900ms ease infinite;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.1); /* Quick scale up for the 'beat' */
    }
    40% {
        transform: scale(1); /* Return to normal */
    }
    60% {
        transform: scale(1.05); /* Slight scale for the second beat */
    }
    80% {
        transform: scale(1); /* Return to normal */
    }
    100% {
        transform: scale(1); /* End at normal size */
    }
}
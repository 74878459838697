.loadingpage {
    width: 100vw;
    height: 100vh;
    background: radial-gradient(circle, var(--mantine-color-primary-7) 0%, var(--mantine-color-primary-9) 75%);
    overflow: hidden;
    z-index: var(--mantine-z-index-max) !important;

    & .loader {
        width: 100%;
        height: 100%;
        background-image:
                radial-gradient(circle at 1px 1px, var(--mantine-color-primary-9) 1px, transparent 0),
                radial-gradient(circle at calc(var(--mantine-custom-components-header-height) / 4) calc(var(--mantine-custom-components-header-height) / 4), var(--mantine-color-primary-9) 1px, transparent 0);
        background-size: calc(var(--mantine-custom-components-header-height) / 2) calc(var(--mantine-custom-components-header-height) / 2);
        animation: heartbeat 900ms ease infinite;

        & .logo {
            fill: var(--mantine-color-primary-6);
            opacity: 0.38;
            width: 30vw;
            /*animation: heartbeat 900ms ease infinite;*/

            @media (max-width: 48em) {
                width: 50vw;
            }
        }
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.3); /* Quick scale up for the 'beat' */
    }
    40% {
        transform: scale(1); /* Return to normal */
    }
    60% {
        transform: scale(1.1); /* Slight scale for the second beat */
    }
    80% {
        transform: scale(1); /* Return to normal */
    }
    100% {
        transform: scale(1); /* End at normal size */
    }
}

/* Keyframes for the zoom in zoom out animation */
@keyframes zoom-in-zoom-out {
    /* At the beginning of the animation */
    0% {
        /* Scale the element to its original size */
        transform: scale(1, 1);
    }
    /* At the middle of the animation */
    50% {
        /* Scale the element to 1.1 times its original size */
        transform: scale(1.1, 1.1);
    }
    /* At the end of the animation */
    100% {
        /* Scale the element back to its original size */
        transform: scale(1, 1);
    }
}

.paper {
    padding-bottom: var(--mantine-spacing-xl);
    margin-bottom: var(--mantine-custom-components-footer-height);
    background-color: var(--mantine-color-primary-0) !important;
    min-height: calc(100vh - var(--mantine-custom-components-footer-height)) !important;
    overflow: hidden;

    @media (max-width: 48em) { /* var(--mantine-breakpoint-sm) */
        margin-bottom: var(--mantine-custom-components-footer-heightMobile);
        min-height: calc(100vh - var(--mantine-custom-components-footer-heightMobile)) !important;
    }
}

.customcarouselindicators {
    padding-bottom: calc(38px + 16px) !important;
}

.pagetitle {

    & .pagetitlebackground {
        position: relative;
        overflow: hidden;
        background: linear-gradient(315deg, var(--mantine-color-primary-7) 0%, var(--mantine-color-primary-9) 75%) !important;
        width: 100%;
        height: 100%;
    }

    & .pagetitlesvg {
        position: absolute;
        pointer-events: none;
        top: 0;
        margin-top: calc(var(--mantine-spacing-xs) * -1);
        margin-right: calc(var(--mantine-breakpoint-sm) / 2);
        left: calc(var(--mantine-spacing-xl) * -1);
        max-width: var(--mantine-breakpoint-sm);
    }

    .pagetitlebackgroundimageroot {
        position: absolute;
        height: calc(100% + 50px);
        width: calc(100% + 50px);
        overflow: hidden;
    }

    .pagetitlebackgroundimage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    & .pagetitleoverlay {
    }

    & .pagetitlesimpletextroot {
        position: relative;
        height: 30vh !important;
        margin-top: calc(var(--mantine-spacing-md) * 4.5);
        margin-bottom: calc(var(--mantine-spacing-md) * 2);

        & .pagetitlesimpletexttext {
            text-align: center;
            color: var(--mantine-color-white);
            padding: calc(var(--mantine-spacing-md) * 3);
        }
    }

    & .pagetitlebox {
        position: relative;
        width: 100%;
        /*padding-top: var(--mantine-custom-components-header-height);*/
        min-height: var(--mantine-custom-components-tab-noSlidesImageHeight) !important;
        /*background-color: var(--mantine-color-primary-8);*/
        background-color: unset;
        /*background-image: radial-gradient(circle at 1px 1px, var(--mantine-color-primary-7) 3px, transparent 0);*/
        /*background-size: calc(var(--mantine-custom-components-header-height) / 3) calc(var(--mantine-custom-components-header-height) / 3);*/
        background-image:
                radial-gradient(circle at 1px 1px, var(--mantine-color-primary-9) 1px, transparent 0),
                radial-gradient(circle at calc(var(--mantine-custom-components-header-height) / 4) calc(var(--mantine-custom-components-header-height) / 4), var(--mantine-color-primary-9) 1px, transparent 0);
        background-size: calc(var(--mantine-custom-components-header-height) / 2) calc(var(--mantine-custom-components-header-height) / 2);

        & .pagetitletitle {
            height: 100% !important;
            min-width: calc(var(--mantine-breakpoint-xs) / 2);
            opacity: 0;
            animation: slide-in-right 200ms ease-out forwards;
            animation-delay: 300ms;

            & .pagetitletitleicon {
                position: absolute;
                pointer-events: none;
                width: calc(var(--mantine-spacing-xl) * 4);
                margin-top: calc(var(--mantine-spacing-xl) * -0.5);
                margin-left: calc(var(--mantine-spacing-xl) * -2.5);
                transform: rotate(-10deg);

                animation: pagetitletitleiconheartbeat 450ms ease-in-out forwards;
                /*animation: pagetitletitleiconheartbeat 900ms ease forwards;*/
                animation-delay: 550ms;
                color: var(--mantine-color-primary-6);
                opacity: 0.18;

                & svg {
                    fill: var(--mantine-color-primary-6);
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            & .pagetitletext {
                position: relative;
                color: var(--mantine-color-white);
            }

            & .pagetitlebreadcrumbroot {
                position: relative;
                margin-top: var(--mantine-spacing-lg);
                /*padding-bottom: var(--mantine-spacing-md);*/
                font-size: var(--mantine-font-size-sm);
            }

            & .pagetitlebreadcrumbtext {
                position: relative;
                color: var(--mantine-color-white) !important;
            }

            & .pagetitlebreadcrumblink {
                position: relative;
                cursor: pointer;
                color: var(--mantine-color-white);
                padding: 2px 10px;
                min-height: 28px;
                min-width: 40px;
                text-align: center;
                background-color: var(--mantine-color-secondary-light-hover);
                border-radius: var(--mantine-radius-default);
                border: 1px solid var(--mantine-color-secondary-6);

                & a {
                    color: var(--mantine-color-white);
                    opacity: 1;

                    & p {
                        font-size: var(--mantine-font-size-sm) !important;
                    }
                }

                &:hover {
                    color: var(--mantine-color-white);
                    background-color: var(--mantine-color-secondary-outline-hover);

                    & a {
                        color: var(--mantine-color-white);
                        opacity: 1;
                    }
                }
            }

            & .pagetitlebreadcrumbseparator {
                position: relative;
                color: var(--mantine-color-white);
                margin: 0 7px;
            }

            & .pagetitledescription {
                position: relative;
                padding-top: var(--mantine-spacing-lg);
                color: var(--mantine-color-white);
            }
        }
    }
}

.pagecardbackgroundsvg {
    position: absolute;
    pointer-events: none;
    top: calc(var(--mantine-spacing-xl) * -1);
    right: calc(var(--mantine-spacing-xl) * -2);
    width: 120%;
    height: 120%;
    opacity: 0.18;
}

.pagecard {
    background-color: var(--mantine-color-tertiary-6) !important;
    /*background: linear-gradient(315deg, var(--mantine-color-tertiary-6) 0%, var(--mantine-color-tertiary-7) 75%) !important;*/
    border: 2px solid var(--mantine-color-tertiary-6) !important;

    & .pagecardsection {
    }
}

.pagetitleimage169root {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; /*Aspect ratio 16:9 (9 / 16 = 0.5625)*/

    & .pagetitleimage169noimage {
        position: absolute;
        background-color: var(--mantine-color-white);
        opacity: 0.12;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;

        & svg {
            color: var(--mantine-color-tertiary-6);
        }
    }

    & .pagetitleimage169image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        filter: contrast(1.1) brightness(0.95);
    }
}

.pagetitleimage169lefticon {
    position: absolute;
    top: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
}

.pagetitleimage169righticon {
    position: absolute;
    top: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
}

@keyframes slide-in-right {
    0% {
        transform: translateX(20%);  /* Start off-screen to the right */
        opacity: 0; /* Optional: Start transparent */
    }
    100% {
        transform: translateX(0);  /* End at the original position */
        opacity: 1; /* Optional: Fully visible */
    }
}

@keyframes pagetitletitleiconbounce {
    0%, 100% {
        transform: scale(1) rotate(-10deg);  /* Start and end at the original size */
    }
    30% {
        transform: scale(1.25) rotate(-10deg);  /* Scale up a bit */
    }
    50% {
        transform: scale(0.9) rotate(-10deg);  /* Slightly shrink down */
    }
    70% {
        transform: scale(1.1) rotate(-10deg);  /* Scale back up slightly */
    }
    90% {
        transform: scale(0.98) rotate(-10deg);  /* Slight shrink before settling */
    }
}

@keyframes pagetitletitleiconheartbeat {
    0% {
        transform: scale(1) rotate(-10deg);
    }
    20% {
        transform: scale(1.3) rotate(-10deg); /* Quick scale up for the 'beat' */
    }
    40% {
        transform: scale(1) rotate(-10deg); /* Return to normal */
    }
    60% {
        transform: scale(1.1) rotate(-10deg); /* Slight scale for the second beat */
    }
    80% {
        transform: scale(1) rotate(-10deg); /* Return to normal */
    }
    100% {
        transform: scale(1) rotate(-10deg); /* End at normal size */
    }
}
@keyframes pulseOpacity {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.timerbuttonactive {
    animation: pulseOpacity 1s infinite;
}
.social {
    @media (max-width: var(--mantine-breakpoint-sm)) {
        margin-top: var(--mantine-spacing-xs);
    }
}

.sociallinksvg {
    width: 80px;
    height: 100%;
    margin-left: calc(var(--mantine-spacing-lg) * 1);
    margin-right: calc(var(--mantine-spacing-lg) * 1);
}

.sociallinkicon {
    width: 60px;
    height: 100%;
    margin-left: calc(var(--mantine-spacing-lg) * 1.5);
    margin-right: calc(var(--mantine-spacing-lg) * 1.5);
}
.modalrootprimary {
    color: var(--mantine-color-white) !important;
}

.modalinnerprimary {
}

.modalheaderprimary {
    background-color: transparent !important;
    /*background-color: var(--mantine-color-primary-9) !important;*/
    /*background: transparent !important;*/
}

.modalcontentprimary {
    background: linear-gradient(315deg, var(--mantine-color-primary-7) 0%, var(--mantine-color-primary-9) 75%) !important;
    /*background-color: var(--mantine-color-primary-9) !important;*/
    /*background: transparent !important;*/
    /*border: 1px solid var(--mantine-color-primary-7) !important;*/
}

.modalbodyprimary {
    /*padding-top: var(--mantine-spacing-lg) !important;*/
    /*padding-right: var(--mantine-spacing-md) !important;*/
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}
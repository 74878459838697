.pillrootprimary {
    color: var(--mantine-color-primary-9) !important;
    background-color: var(--mantine-color-primary-outline-hover) !important;
}

.pillrootsecondary {
    color: var(--mantine-color-secondary-9) !important;
    background-color: var(--mantine-color-secondary-outline-hover) !important;
}

.pillrootwhite {
    color: var(--mantine-color-white) !important;
    background-color: rgba(255, 255, 255, 0.12) !important;
}

.pillrootprimary {
    color: var(--mantine-color-primary-9) !important;
    background-color: var(--mantine-color-primary-outline-hover) !important;
}
.overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: calc(var(--mantine-z-index-overlay) + 1);
    height: var(--mantine-custom-components-header-height) !important;
    /*background-color: var(--mantine-color-primary-6) !important;*/
    /*background-color: var(--mantine-color-gray-1);*/
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: calc(var(--mantine-z-index-overlay) + 1);
    height: var(--mantine-custom-components-header-height) !important;
    padding-top: calc(var(--mantine-spacing-md) - 1px);
    padding-bottom: calc(var(--mantine-spacing-md) - 1px);
    padding-left: 0;
    padding-right: 0;
    /*border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));*/
    background-color: var(--mantine-color-primary-6) !important;
    /*background-color: transparent!important;*/
    transition: transform 200ms ease;
}

.headerdrawerroot {

    & .headerdrawerheader {
    }

    & .headerdrawercontent {
        color: var(--mantine-color-white);
        margin-top: var(--mantine-custom-components-header-height) !important;
        height: calc(100% - var(--mantine-custom-components-header-height)) !important;
        /*padding-bottom: calc(var(--mantine-custom-components-header-height) * -3);*/
        /*border-right: 2px solid var(--mantine-color-white);*/
        /*background: linear-gradient(135deg, var(--mantine-color-secondary-7) 0%, var(--mantine-color-secondary-9) 75%) !important;*/
        background-color: var(--mantine-color-secondary-6);
    }

    & .headerdrawerbody {
        padding: 0 !important;

    }
}

.menudropdownsmsearch {
    position: fixed !important;
    background-color: var(--mantine-color-primary-6) !important;
    border-radius: 0 !important;
    top: 0 !important;
    left: 58px /*left ico*/ !important;
    width: calc(100% - 58px /*left ico*/ - 104px /*right icon*/ ) !important;
    height: var(--mantine-custom-components-header-height) !important;
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: var(--mantine-spacing-md) !important;
    padding-bottom: var(--mantine-spacing-md) !important;

}

.searchmenutarget {
    width: 18px;
    height: 18px;
    cursor: pointer;

    &:hover {
        opacity: 0.75;
    }
}

.link {
    display: flex;
    align-items: center;
    height: 100%;
    /*padding-left: var(--mantine-spacing-md);*/
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
    margin-right: 16px !important;

    @media (max-width: $mantine-breakpoint-sm) {
        height: rem(42px);
        width: 100%;
    }

    @mixin hover {
        background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
}

/*.subLink {*/
/*    width: 100%;*/
/*    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);*/

/*    @mixin hover {*/
/*        background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));*/
/*    }*/
/*}*/

/*.dropdownFooter {*/
/*    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));*/
/*    margin: calc(var(--mantine-spacing-md) * -1);*/
/*    margin-top: var(--mantine-spacing-sm);*/
/*    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);*/
/*    padding-bottom: var(--mantine-spacing-xl);*/
/*    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));*/
/*}*/

.headersarchinputinput {

    color: var(--mantine-color-secondary-9) !important;
    border-color: var(--mantine-color-secondary-2) !important;
    background-color: var(--mantine-color-secondary-0) !important;
    height: 38px !important;

    &:focus {
        border-color: var(--mantine-color-secondary-6) !important;
    }

    &::placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }
}

.toolsfixed {
    position: absolute;
    width: 100%;
    padding: 0 !important;
    padding-right: calc(var(--mantine-spacing-md) * 2) !important;
}

.tools {
    max-width: 100vw;
    margin: 0 auto;
}

.anchor {
    color: var(--mantine-color-white)  !important;
    /*font-size: var(--mantine-font-size-sm) !important;*/
    margin-left: 32px !important;
    text-wrap: nowrap !important;
}

.anchorxs {
    margin-left: calc(var(--mantine-spacing-md) * 4) !important;
    color: var(--mantine-color-white) !important;
}

.anchorStatic {
    color: var(--mantine-color-white) !important;
    /*font-size: var(--mantine-font-size-sm) !important;*/
    margin-left: 38px !important;
    padding-bottom: var(--mantine-spacing-md) !important;
}

.anchorStaticnopadding {
    color: var(--mantine-color-white) !important;
    /*font-size: var(--mantine-font-size-sm) !important;*/
    margin-left: 38px !important;
}

.anchorxsroot {
    cursor: pointer;
    margin-left: 15px !important;
    color: var(--mantine-color-white)  !important;
}

.dividerlabel {
    color: var(--mantine-color-white);
    font-size: var(--mantine-font-size-sm);
    font-weight: 700;
}

.headerbutton {

    color: var(--mantine-color-white) !important;
    min-width: 100px !important;
    min-height: 38px !important;
    /*border: 2px solid var(--mantine-color-white) !important;*/
    /*& svg:hover {*/
    /*    opacity: 0.75;*/
    /*}*/
}

.menutargetmolecules {

    color: var(--mantine-color-white) !important;
    min-width: 140px !important;
    min-height: 38px !important;
    /*border: 2px solid var(--mantine-color-white) !important;*/
    /*& svg:hover {*/
    /*    opacity: 0.75;*/
    /*}*/
}

.menutargetrecipes {

    color: var(--mantine-color-white) !important;
    min-width: 140px !important;
    min-height: 38px !important;
    /*border: 2px solid var(--mantine-color-white) !important;*/
    /*& svg:hover {*/
    /*    opacity: 0.75;*/
    /*}*/
}

.menutargeticon {
    width: 18px !important;
    fill: var(--mantine-color-white) !important;
}

.scrollbar {

    &,
    &:hover {
        background-color: var(--mantine-color-secondary-6) !important;
    }

    &[data-orientation='vertical'] {

        & .thumb {
            background-color: var(--mantine-color-secondary-7) !important;

            &:before {
                background-color: var(--mantine-color-secondary-7) !important;
            }
        }
    }
}

.corner {
    background-color: var(--mantine-color-secondary-6) !important;
}
.moleculecardtable {

    color: var(--mantine-color-white) !important;

    & tbody {

        & tr {
            height: 30px !important;

            & td {
                padding-bottom: 0;
            }

            &:first-child {

                & td {
                    padding-top: 0;
                }
            }
        }
    }
}
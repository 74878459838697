/*.root {*/
/*    flex: 1;*/
/*    background-color: var(--mantine-color-white);*/
/*    max-height: 36px;*/
/*    border-radius: var(--mantine-radius-default);*/
/*    border: 2px solid var(--mantine-color-white);*/
/*}*/

.menudropdown {
    background-color: var(--mantine-color-secondary-6) !important;
    border: 2px solid var(--mantine-color-white) !important;
}

.menuarrow {
    background-color: var(--mantine-color-secondary-6) !important;
    border-top: 2px solid var(--mantine-color-white) !important;
    border-left: 2px solid var(--mantine-color-white) !important;
}

.menuitem {
    color: var(--mantine-color-white) !important;
    background-color: transparent !important;
}

/*.selection {*/
/*    fill: var(--mantine-color-gray-4);*/
/*    !*opacity: 0.25;*!*/
/*    width: 18px;*/
/*    height: 18px;*/
/*}*/

.sarchinputinput {

    color: var(--mantine-color-secondary-9) !important;
    border-color: var(--mantine-color-secondary-2) !important;
    background-color: var(--mantine-color-secondary-outline-hover) !important;
    height: 38px !important;

    &:focus {
        border-color: var(--mantine-color-secondary-6) !important;
    }

    &::placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }
}

.sectionpaper {
    padding: var(--mantine-spacing-md) !important;
    background-color: var(--mantine-color-secondary-filled-hover) !important;

}

.leftsection {

    &:disabled {
        background-color: transparent !important;
        cursor: auto;
        pointer-events: none;
    }
}

/*.searchdrawerroot {*/

/*    & .searchdrawercontent {*/
/*        color: var(--mantine-color-white);*/
/*        margin-top: var(--mantine-custom-components-header-height);*/
/*        padding-bottom: calc(var(--mantine-custom-components-header-height) * 1);*/
/*        !*border-right: 2px solid var(--mantine-color-white);*!*/
/*        !*background: linear-gradient(135deg, var(--mantine-color-secondary-7) 0%, var(--mantine-color-secondary-9) 75%) !important;*!*/
/*        background-color: var(--mantine-color-secondary-6);*/
/*        border-bottom: 2px solid var(--mantine-color-white);*/
/*    }*/

/*    & .searchdrawerbody {*/
/*    }*/
/*}*/

.subcomponentroot {

    margin-top: var(--mantine-spacing-lg);

    & .subcomponentbutton {
        cursor: pointer;
        color: var(--mantine-color-white);
        padding: var(--mantine-spacing-md);
        border-radius: var(--mantine-radius-default);
        background-color: var(--mantine-color-secondary-light-hover);
        border: 1px solid var(--mantine-color-secondary-6);
        height: 100%;

        &:hover {
            background-color: var(--mantine-color-secondary-outline-hover);
        }
    }
}

.image169withzoomanchor {

    &:hover {
        opacity: 1;
    }
}

.pairingsctaicon {
    width: 60px;
    height: 100%;
    fill: var(--mantine-color-secondary-6);
    margin-left: calc(var(--mantine-spacing-lg) * 1.5);
    margin-right: calc(var(--mantine-spacing-lg) * 1.5);
}

.image169withzoomicon {
    color: var(--mantine-color-white);
    padding-top: 10px !important;
    border-radius: var(--mantine-radius-default);
    background-color: var(--mantine-color-tertiary-6);
    border: 1px solid var(--mantine-color-tertiary-6);
    width: 38px !important;
    height: 38px !important;
}

.ingredientcardtable {

    color: var(--mantine-color-white);

    & tbody {

        & tr {
            height: 30px !important;

            & td {
                padding-bottom: 0;
            }

            &:first-child {

                & td {
                    padding-top: var(--mantine-spacing-md);
                }
            }
        }
    }
}

.rangeslidertrack {

    &::before {
        background-color: var(--mantine-color-secondary-6) !important;
        opacity: 0.20 !important;
    }
}

.rangeslidermarklabel {
    /*color: var(--mantine-color-black) !important;*/
    color: var(--mantine-color-secondary-9) !important;
    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700;
}

.rangeslidermark {
    background-color: var(--mantine-color-white) !important;
    border: 1px solid var(--mantine-color-secondary-filled-hover) !important;
}

.moleculepairingscontainertitleroot {
}

.moleculepairingscontainerroot {
}

.moleculedrawerroot {

    & .moleculedrawercontent {
        border-top-left-radius: var(--mantine-radius-default);
        border-top-right-radius: var(--mantine-radius-default);
        /*margin-top: var(--mantine-custom-components-header-height);*/
        /*padding-bottom: calc(var(--mantine-custom-components-header-height) * 1);*/
        background-color: var(--mantine-color-secondary-0);
    }

    & .moleculedrawerbody {
        padding: 0;
    }

    & .moleculedrawerheader {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        background-color: var(--mantine-color-secondary-0);
    }

    & .moleculedrawertitle {
        color: var(--mantine-color-secondary-9);
        width: 100%;
        padding-bottom: var(--mantine-spacing-lg);
    }

    & .moleculedrawericon {
        width: 64px !important;
        height: 64px !important;
        fill: var(--mantine-color-secondary-9);
    }
}

.moleculepairingstdinactive {
    padding: 0 !important;
    min-height: 60px !important;
}

.moleculepairingstd {
    padding: 0 !important;
    min-height: 60px !important;

    color: var(--mantine-color-secondary-9);
    background-color: var(--mantine-color-secondary-outline-hover);

    & svg {
        width: 18px;
        fill: var(--mantine-color-secondary-9);
    }

    &:hover {
        color: var(--mantine-color-white);
        background-color: var(--mantine-color-secondary-6);

        & svg {
            width: 18px;
            fill: var(--mantine-color-white);
        }
    }
}
.recipenotificationroot {
    color: var(--mantine-color-white) !important;
    background-color: var(--mantine-color-tertiary-6) !important;
    outline: 2px solid var(--mantine-color-tertiary-6) !important;
    padding: var(--mantine-spacing-xs) !important;
    padding-left: 0 !important;
}

.recipenotificationicon {
    width: 40% !important;
}

.recipenotificationtitle {
    color: var(--mantine-color-white) !important;
}

.recipenotificationdescription {
    color: var(--mantine-color-white) !important;
}

.recipenotificationclosebutton {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    color: var(--mantine-color-white) !important;
    background-color: trasparent !important;

    & svg {
        position: absolute !important;
        right: var(--mantine-spacing-md) !important;
    }

    &:hover {
        background-color: transparent !important;
        opacity: 0.75;
    }
}

& .image169root {
    width: 100% !important;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; /*Aspect ratio 16:9 (9 / 16 = 0.5625)*/

    .image169image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        filter: contrast(1.1) brightness(0.95);
    }

    .image169lefticon {
        position: absolute;
        top: calc(50% - 48px / 2);
        left: calc(50% - 39px / 2);
    }
}


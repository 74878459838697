/* xyflow theme files. Delete these to start from our base */

.react-flow {
    /* Custom Variables */
    --xy-theme-selected: var(--mantine-color-primary-6);
    --xy-theme-hover: var(--mantine-color-primary-light-hover);
    --xy-theme-edge-hover: red;
    --xy-theme-color-focus: red;

    /* Built-in Variables see https://reactflow.dev/learn/customization/theming */
    --xy-node-border-default: 4px solid var(--mantine-color-primary-2);

    /*--xy-node-boxshadow-default:*/
    /*        0 0 0 rgba(92, 58, 84, 0.3),*/
    /*        0 0 calc(0.5rem * var(--mantine-scale)) rgba(92, 58, 84, 0.3);*/

    --xy-node-boxshadow-default: none;

    --xy-node-border-radius-default: 50%;

    --xy-handle-background-color-default: red;
    --xy-handle-border-color-default: red;

    --xy-edge-label-color-default: red;

    /*user-select: none;*/
}

.react-flow.dark {
    --xy-node-boxshadow-default:
            0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.05), /* light shadow */
            0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.13), /* medium shadow */
            0px 0.51px 1.01px 0px rgba(255, 255, 255, 0.2);  /* smallest shadow */
    --xy-theme-color-focus: #535353;
}

/*.react-flow__renderer,*/
/*.react-flow__pane,*/
/*.react-flow__viewport {*/
/*    pointer-events: auto !important;*/
/*    z-index: 1 !important;*/
/*}*/

/*.react-flow__panel {*/
/*    pointer-events: auto !important;*/
/*    z-index: 0 !important;*/
/*}*/

.react-flow__selection {
    background-color: var(--mantine-color-tertiary-outline-hover);
    border: 1px dashed var(--mantine-color-tertiary-6);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.react-flow__nodesselection-rect {
    background-color: var(--mantine-color-tertiary-outline-hover);
    border: 1px dashed var(--mantine-color-tertiary-6);
    border-radius: 8px;
}

/* Customizing Default Theming */

.react-flow__node {
    /*box-shadow: var(--xy-node-boxshadow-default);*/
    box-shadow: none;
    border-radius: var(--xy-node-border-radius-default);
    background-color: var(--mantine-color-body);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    flex-direction: column;
    border: var(--xy-node-border-default);
    /*color: var(--xy-node-color, var(--xy-node-color-default));*/
}

.react-flow__node.selectable:focus {
    /*box-shadow: var(--xy-node-boxshadow-default);*/
    box-shadow: none;
    border-color: var(--xy-theme-selected);
}

.react-flow__node.selectable:focus:active {
    /*box-shadow: var(--xy-node-boxshadow-default);*/
    box-shadow: none;
    border-color: var(--xy-theme-selected);
}

.react-flow__node.selectable:hover,
.react-flow__node.draggable:hover {
    /*box-shadow: var(--xy-node-boxshadow-default);*/
    box-shadow: none;
    border-color: var(--xy-theme-selected);
}

.react-flow__node.selectable.selected {
    /*box-shadow: var(--xy-node-boxshadow-default);*/
    box-shadow: none;
    border-color: var(--xy-theme-selected);
}

.react-flow__edge.selectable:hover .react-flow__edge-path,
.react-flow__edge.selectable.selected .react-flow__edge-path {
    stroke: var(--xy-theme-edge-hover);
}

.react-flow__handle {
    background-color: var(--xy-handle-background-color-default);
}

.react-flow__handle.connectionindicator:hover {
    pointer-events: auto;
    border-color: var(--xy-theme-edge-hover);
    background-color: white;
}

.react-flow__handle.connectionindicator:focus ,
.react-flow__handle.connectingfrom,
.react-flow__handle.connectingto {
    border-color: var(--xy-theme-edge-hover);
}

.react-flow__node-resizer {
    border-radius: 0;
    border: none;
}

.react-flow__resize-control.handle {
    background-color: var(--mantine-color-secondary-6);
    border: 2px solid var(--mantine-color-body);
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.react-flow__node-group {
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer !important;
    border-width: 2px;
    border-radius: 8px;
    z-index: -1 !important;

    &.dragging {
        cursor: grabbing !important;
    }

    &.selectable,
    &.selectable:hover,
    &.selectable.selected,
    &.selectable:focus:active {
        box-shadow: none;
    }
}

.react-flow__node-input {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer !important;
    border-width: 8px;
    border-color: var(--mantine-color-primary-4);

    &.dragging {
        cursor: grabbing !important;
    }
}

.react-flow__node-intermediate {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer !important;

    &.dragging {
        cursor: grabbing !important;
    }
}

.react-flow__node-custom {
    /*width: 100px;*/
    /*height: 100px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer !important;

    &.dragging {
        cursor: grabbing !important;
    }
}

.react-flow__edges {

    & svg:has(g.is-group) {
        /*z-index: -1 !important;*/
    }
}

/* Minimap */

.react-flow__minimap .group {
    fill: var(--mantine-color-primary-outline-hover);
    rx: 1%;
    ry: 1%;
}

.react-flow__minimap .input {
    fill: var(--mantine-color-primary-6);
    rx: 100%;
    ry: 100%;
}

.react-flow__minimap .intermediate {
    fill: var(--mantine-color-primary-6);
    opacity: 0.25;
    rx: 100%;
    ry: 100%;
}

.react-flow__minimap .custom {
    fill: var(--mantine-color-primary-6);
    opacity: 0.125;
    rx: 100%;
    ry: 100%;
}

.react-flow__minimap {
    /*cursor: grab;*/
    background-color: var(--mantine-color-secondary-0);
    border-radius: 8px;

    margin-bottom: 48px;

    & svg {
        border-radius: 8px;
    }
}

/*.react-flow__controls {*/

/*    box-shadow: none;*/

/*    & .react-flow__controls-button {*/
/*        color: var(--mantine-color-white);*/
/*        fill: var(--mantine-color-white);*/
/*        background-color: var(--mantine-color-secondary-6);*/
/*        border: none;*/

/*        &:hover {*/
/*            background-color: var(--mantine-color-secondary-filled-hover);*/
/*        }*/

/*        &:first-child {*/
/*            border-top-left-radius: 4px;*/
/*            border-top-right-radius: 4px;*/
/*        }*/

/*        &:last-child {*/
/*            border-bottom-left-radius: 4px;*/
/*            border-bottom-right-radius: 4px;*/
/*        }*/
/*    }*/
/*}*/




.recipescardtable {

    color: var(--mantine-color-white);

    & tbody {

        & tr {
            height: 30px !important;

            & td {
                padding-bottom: 0;
            }

            &:first-child {

                & td {
                    padding-top: 0;
                }
            }
        }
    }
}


.recipegptlink {
    margin-top: var(--mantine-spacing-lg) !important;
    background-color: var(--mantine-color-secondary-light-hover) !important;
    border: 1px solid var(--mantine-color-secondary-6) !important;

    &:hover {
        opacity: 1 !important;
        background-color: var(--mantine-color-secondary-outline-hover) !important;
    }
}
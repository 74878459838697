.homesvgouter {
    position: relative;
}

.homesvg {
    position: absolute;
    pointer-events: none;
    top: 0;
    /*margin-top: calc(var(--mantine-spacing-md) * -1);*/
    /*margin-left: calc(var(--mantine-breakpoint-xs) / 2);*/
    margin-left: 0;
    /*margin-right: calc(var(--mantine-breakpoint-xs) / 2);*/
    margin-right: 0;
    /*max-width: var(--mantine-breakpoint-xl);*/
    width: 100%;
    z-index: 0;
    /*opacity: 0.12;*/
}

.homesimpletext {

    @media (max-width: 48em) {
        margin-top:  calc(var(--mantine-spacing-md) * -2);
        font-size: 22px !important;
    }
}

.foodpairingicon {
    width: 60px;
    height: 100%;
    fill: var(--mantine-color-tertiary-6);
    margin-left: calc(var(--mantine-spacing-lg) * 1.5);
    margin-right: calc(var(--mantine-spacing-lg) * 1.5);
}

.homelogo {
    width: 400px;
    height: 100%;
    fill: var(--mantine-color-white);
    padding-bottom: calc(var(--mantine-spacing-lg) * 1);

    @media (max-width: 48em) {
        margin-bottom: calc(var(--mantine-spacing-md) * -1);
        width: 250px;
    }
}

.fpslogo {
    width: 500px;
    height: 100%;
    fill: var(--mantine-color-white);
    padding-bottom: calc(var(--mantine-spacing-lg) * 1);

    @media (max-width: 48em) {
        margin-bottom: calc(var(--mantine-spacing-md) * -1);
        width: 250px;
    }
}

.homeicon {
    width: 100px;
    height: 100%;
    fill: var(--mantine-color-white);
    padding-bottom: calc(var(--mantine-spacing-md) * 1);

    @media (max-width: 48em) {
        margin-bottom: calc(var(--mantine-spacing-md) * -2);
        width: 80px;
    }
}

.homeplusicon {
    width: 50px;
    height: 100%;
    margin-left: -16px;
    fill: var(--mantine-color-white);
    padding-bottom: calc(var(--mantine-spacing-md) * 1);

    @media (max-width: 48em) {
        margin-bottom: calc(var(--mantine-spacing-md) * -2);
        width: 30px;
    }
}

.homegpticon {
    width: 150px;
    height: 100%;
    fill: var(--mantine-color-white);
    padding-bottom: calc(var(--mantine-spacing-md) * 1);

    @media (max-width: 48em) {
        margin-bottom: calc(var(--mantine-spacing-md) * -2);
        width: 100px;
    }
}

.homeheartbeaticon {
    animation: heartbeat 900ms ease infinite;
}

.modalroot {
    color: var(--mantine-color-white) !important;
}

.modalinner {
}

.modalheader {
    background-color: var(--mantine-color-primary-9) !important;
    padding: 0 !important;
    /*background: transparent !important;*/
}

.modalcontent {
    background: linear-gradient(315deg, var(--mantine-color-primary-7) 0%, var(--mantine-color-primary-9) 75%) !important;
    /*background: transparent !important;*/
    /*border: 1px solid var(--mantine-color-primary-7) !important;*/
}

.modalbody {
    padding-top: var(--mantine-spacing-lg) !important;
    padding-right: var(--mantine-spacing-md) !important;
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}

.customcarouselindicators {
    padding-bottom: 0 !important;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    20% {
        transform: scale(1.1); /* Quick scale up for the 'beat' */
        opacity: 0.8; /* Slight fade for emphasis */
    }
    40% {
        transform: scale(1); /* Return to normal */
        opacity: 1;
    }
    60% {
        transform: scale(1.05); /* Slight scale for the second beat */
        opacity: 0.9; /* Subtle fade */
    }
    80% {
        transform: scale(1); /* Return to normal */
        opacity: 1;
    }
    100% {
        transform: scale(1); /* End at normal size */
        opacity: 1;
    }
}
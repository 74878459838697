.scrollbar {

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='horizontal'] {

        & .thumb {
            background-color: var(--mantine-color-secondary-6);

            &:before {
                background-color: var(--mantine-color-secondary-6);
            }
        }
    }
}

.corner {
    width: 0;
    height: 38px;
    background-color: #1f1f1f;
}

.navigation {
    position: absolute;
    top: 0;
    height: 38px;
    text-align: center;
    vertical-align: center;

    & svg {
        padding-top: 11px;

        & path {
            fill: var(--mantine-color-white) !important;
        }
    }
}

.scrolltabtabroot {
    margin-right: 1px !important;
    margin-left: 1px !important;
    min-height: 38px !important;
    color: var(--mantine-color-primary-0) !important;
    background-color: var(--mantine-color-secondary-6) !important;
    border-top-left-radius: var(--mantine-radius-default) !important;
    border-top-right-radius: var(--mantine-radius-default) !important;

    &:hover {
        background-color: var(--mantine-color-secondary-filled-hover) !important;
    }

    &[data='selected'] {

        color: var(--mantine-color-secondary-6) !important;
        background-color: var(--mantine-color-primary-0) !important;

        & svg {
            fill: var(--mantine-color-secondary-6) !important;
        }

        &:hover {
            color: var(--mantine-color-secondary-6) !important;
            background-color: var(--mantine-color-primary-0) !important;

            & svg {
                fill: var(--mantine-color-secondary-6) !important;
            }
        }
    }

    & svg {
        fill: var(--mantine-color-primary-0) !important;
    }
}
.paginationcontrol {

    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border: transparent !important;
    background-color: var(--mantine-color-secondary-outline-hover) !important;
    color: var(--mantine-color-secondary-9) !important;

    &:hover {
        background-color: var(--mantine-color-secondary-light-hover) !important;
    }

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;
        background-color: var(--mantine-color-secondary-6) !important;
    }

    &[data-disabled="true"] {
        background-color: transparent !important;
    }
}

.paginationdots {
    color: var(--mantine-color-secondary-9) !important;
}
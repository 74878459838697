@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: zoom-in-zoom-out 1s ease infinite;
  }
}

.App-header {
  background-color: #EEEEEE;
  color: #EF2AC1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.App-link {
  color: #EF2AC1;
  text-decoration: none;
  padding: 8px;
}

/* Keyframes for the zoom in zoom out animation */
@keyframes zoom-in-zoom-out {
  /* At the beginning of the animation */
  0% {
    /* Scale the element to its original size */
    transform: scale(1, 1);
  }
  /* At the middle of the animation */
  50% {
    /* Scale the element to 1.1 times its original size */
    transform: scale(1.1, 1.1);
  }
  /* At the end of the animation */
  100% {
    /* Scale the element back to its original size */
    transform: scale(1, 1);
  }
}
.modalrootsecondary {
    /*color: var(--mantine-color-white) !important;*/
}

.modalinnersecondary {
}

.modalheadersecondary {
    /*background-color: var(--mantine-color-secondary-6) !important;*/
    padding: 0 !important;
    background: transparent !important;
    padding-right: var(--mantine-spacing-md) !important;
    padding-left: var(--mantine-spacing-md) !important;

    & h2 {
        width: 100%;
    }
    /*& svg {*/
    /*    color: var(--mantine-color-secondary-6) !important;*/
    /*}*/
}

.modalcontentsecondary {
    background: linear-gradient(315deg, var(--mantine-color-secondary-7) 0%, var(--mantine-color-secondary-9) 75%) !important;
    /*background-color: var(--mantine-color-secondary-6) !important;*/
    /*background: transparent !important;*/
}

.modalbodysecondary {
    /*padding-top: var(--mantine-spacing-lg) !important;*/
    /*padding-right: calc(var(--mantine-spacing-xs) / 4) !important;*/
    /*padding-bottom: calc(var(--mantine-spacing-xs) / 4) !important;*/
    /*background-color: var(--mantine-color-secondary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
    padding: 0 !important;
}

.modalroottertiary {
    /*color: var(--mantine-color-white) !important;*/
}

.modalinnertertiary {
}

.modalheadertertiary {
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    padding: 0 !important;
    background: transparent !important;
    padding-right: var(--mantine-spacing-md) !important;
    padding-left: var(--mantine-spacing-md) !important;

    & h2 {
        width: 100%;
    }
    /*& svg {*/
    /*    color: var(--mantine-color-secondary-6) !important;*/
    /*}*/
}

.modalcontenttertiary {
    background: linear-gradient(315deg, var(--mantine-color-tertiary-7) 0%, var(--mantine-color-tertiary-9) 75%) !important;
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*background: transparent !important;*/
}

.modalbodytertiary {
    /*padding-top: var(--mantine-spacing-lg) !important;*/
    /*padding-right: calc(var(--mantine-spacing-xs) / 4) !important;*/
    /*padding-bottom: calc(var(--mantine-spacing-xs) / 4) !important;*/
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
    padding: 0 !important;
}

.featureslinkicon {
    width: 60px;
    height: 100%;
    margin-left: calc(var(--mantine-spacing-lg) * 1.5);
    margin-right: calc(var(--mantine-spacing-lg) * 1.5);
    color: var(--mantine-color-secondary-6);
}

.table {

    & thead {

        & tr {

            & th {

                &:first-child {
                    padding-left: var(--mantine-spacing-md) !important;
                }

                &:last-child {
                    padding-right: var(--mantine-spacing-lg) !important;
                }
            }
        }
    }

    & tbody {

        & tr {
            height: 30px;

            & td {

                border-radius: 0 !important;

                &:first-child {
                    padding-left: var(--mantine-spacing-md) !important;
                }

                &:last-child {
                    padding-right: var(--mantine-spacing-lg) !important;
                }
            }
        }
    }
}

.scrollbar {

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='vertical'], &[data-orientation='horizontal'] {

        & .thumb {
            background-color: var(--mantine-color-gray-2) !important;

            &:before {
                background-color: var(--mantine-color-gray-2) !important;
            }
        }
    }
}

.corner {
    background-color: transparent !important;
}

.segmentedplansrootsecondary {
    background-color: var(--mantine-color-secondary-2) !important;
}

.segmentedplanslabelsecondary {

    color: var(--mantine-color-secondary-9) !important;

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;

        &:hover {
            color: var(--mantine-color-white) !important;
        }
    }

    &:hover {
        color: var(--mantine-color-secondary-6) !important;
    }
}

.segmentedplansroottertiary {
    background-color: var(--mantine-color-tertiary-2) !important;
}

.segmentedplanslabeltertiary {

    color: var(--mantine-color-tertiary-9) !important;

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;

        &:hover {
            color: var(--mantine-color-white) !important;
        }
    }

    &:hover {
        color: var(--mantine-color-tertiary-6) !important;
    }
}
.accountcardtable {

    color: var(--mantine-color-white);

    & tbody {

        & tr {
            height: 30px !important;

            & td {
                padding-bottom: 0;
            }

            &:first-child {

                & td {
                    padding-top: 0;
                }
            }
        }
    }
}

.accountinputlabel {

    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    padding-bottom: var(--mantine-font-size-xs) !important;
}

.accountinputinput {

    color: var(--mantine-color-secondary-9) !important;
    border-color: var(--mantine-color-secondary-2) !important;
    /*background-color: var(--mantine-color-secondary-outline-hover) !important;*/
    height: 38px !important;

    &:focus {
        border-color: var(--mantine-color-secondary-6) !important;
    }

    &::placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }
}

.pillroot {
    color: var(--mantine-color-secondary-9) !important;
    background-color: var(--mantine-color-secondary-light-hover) !important;
}

.table {

    & tbody {

        & tr {
            height: 60px;


            & td {
                /*border-bottom: 2px solid var(--mantine-color-primary-0);*/

                &:first-child {
                    border-top-left-radius: var(--mantine-radius-default);
                    border-bottom-left-radius: var(--mantine-radius-default);
                }

                &:last-child {
                    border-top-right-radius: var(--mantine-radius-default);
                    border-bottom-right-radius: var(--mantine-radius-default);
                }
            }
        }
    }
}

.workrecipeimage169root {
    width: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    & .workrecipeimage169image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        filter: contrast(1.1) brightness(0.95);
    }

    & .workrecipeimage169overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        & .workrecipeimage169title {
            position: relative;
            z-index: 2;
        }
    }
}

.checkboxinput {
    border: 1px solid var(--mantine-color-secondary-6) !important;
}

.simpleboxbackground {
    padding: var(--mantine-spacing-md);
    width: 100%;
    height: 100%;
    border-radius: var(--mantine-radius-md);
}
.carouselcontrol {
    color: var(--mantine-color-white) !important;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: none !important;

    & svg {
        width: 18px !important;
        height: 18px !important;
    }
}

.carouselcontrolprimary {
    background-color: var(--mantine-color-primary-6) !important;
}

.carouselcontrolsecondary {
    background-color: var(--mantine-color-secondary-6) !important;
}

.carouselcontroltertiary {
    background-color: var(--mantine-color-tertiary-6) !important;
}

.carouselindicators {
    /*padding-bottom: 38px !important;*/
}

.carouselindicator {
    margin-right: 3px !important;
    margin-Left: 3px !important;
    width: 12px !important;
    height: 12px !important;
    transition: width 250ms ease !important;

    &[data-active] {
        width: 38px !important;
    }
}

.carouselindicatorprimary {
    background-color: var(--mantine-color-primary-6) !important;
}

.carouselindicatorsecondary {
    background-color: var(--mantine-color-secondary-6) !important;
}

.carouselindicatortertiary {
    background-color: var(--mantine-color-tertiary-6) !important;
}
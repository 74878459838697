.menudropdown {
    /*background-color: var(--mantine-color-secondary-6) !important;*/
    background-color: transparent !important;
    border: 2px solid var(--mantine-color-secondary-6) !important;
    padding: var(--mantine-spacing-md) !important;

    & .menudropdownoverlay {
        position: absolute !important;
        border-radius: 6px !important;
        z-index: 1 !important;
    }

    & .menudropdowncontent {
        position: relative !important;
        width: 100%;
        height: 100%;
        z-index: 2 !important;
    }
}

.menuarrow {
    border: transparent !important;
    background: linear-gradient(135deg, var(--mantine-color-secondary-6) 50%, transparent 50%) !important;
    transform: rotate(45deg) !important;
}

.menuitem {
    color: var(--mantine-color-white) !important;
    background-color: transparent !important;
    font-size: var(--mantine-font-size-md) !important;

    &:hover {
        opacity: 0.75 !important;
    }

    &:last-child {
        padding-bottom: 0 !important;
    }
}
.menutarget {

    & svg {
        padding-top: calc(var(--mantine-spacing-xs) / 2);
        width: 24px;

        &:hover {
            opacity: 0.75;
        }
    }
}


.modalroot {
    color: var(--mantine-color-tertiary-9) !important;
}

.modalinner {
}

.modalheader {
    background-color: transparent !important;
    /*background-color: var(--mantine-color-body) !important;*/
    padding: 0 !important;
    /*background: transparent !important;*/
}

.modalcontent {
    background: linear-gradient(315deg, var(--mantine-color-tertiary-7) 0%, var(--mantine-color-tertiary-9) 75%) !important;
    /*background-color: var(--mantine-color-body) !important;*/
    /*background: transparent !important;*/
}

.modalbody {
    padding-top: var(--mantine-spacing-lg) !important;
    padding-right: var(--mantine-spacing-md) !important;
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}

.anchor {
    color: var(--mantine-color-white)  !important;
    /*font-size: var(--mantine-font-size-sm) !important;*/
}

.bannersvg {
    width: 80px;
    height: 100%;
    margin-left: calc(var(--mantine-spacing-lg) * 1);
    margin-right: calc(var(--mantine-spacing-lg) * 1);
}
.paper {
    /*background: linear-gradient(315deg, rgba(255, 95, 31, 0.2) 0%, rgba(239, 42, 193, 0.5) 75%) !important;*/
    /*background: white !important;*/
}

.typesimagewithzoomroot {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 50%;

    & .typesimagewithzoomimage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: contrast(1.1) brightness(0.95);
    }

    /*& .imagewithzoomoverlay {*/
    /*    position: relative;*/
    /*    bottom: 0;*/
    /*    left: 0;*/
    /*    width: 100%;*/
    /*    height: 100%;*/

    /*    & .imagewithzoomtitle {*/
    /*        position: absolute;*/
    /*        padding-top: 100%;*/
    /*        background-color: #9E86ED;*/
    /*        color: #EF2AC1;*/
    /*        width: 100%;*/
    /*        height: 100%;*/
    /*        z-index: 1000000;*/
    /*    }*/
    /*}*/
}

.typesimagewithzoomnoimage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--mantine-color-primary-light-hover);
}

.generatedrecipeimageroot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    filter: contrast(1.1) brightness(0.95);
}

.detailsimagewithzoomroot {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--mantine-radius-md);
    background-color: var(--mantine-color-tertiary-outline-hover);
    padding-bottom: 56.25%; /*Aspect ratio 16:9 (9 / 16 = 0.5625)*/

    & .detailsimagewithzoomimage {
        position: absolute;
        width: 100%;
        height: 100%;
        filter: contrast(1.1) brightness(0.95);
    }

    & .detailsimagewithzoomnoimage {
        width: 100%;
        height: 100%;

        border: none;

        & svg {
            position: absolute;
            top: calc(35%);
            left: 50%;
            transform: translate(-50%, -50%); /* Center */
            & path {
                fill: var(--mantine-color-tertiary-light-hover)
            }
        }
    }
}

.addinputnode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center */

    & .addinputnodebutton {
        font-size: 2rem;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.projectloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center */
    width: 60%;
}

.projectloadertab {

    color: var(--mantine-color-secondary-6) !important;

    /*&[data-active="false"] {*/
    /*    color: var(--mantine-color-secondary-6) !important;*/
    /*}*/

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;
    }

    &:hover {
        color: var(--mantine-color-white) !important;
        background-color: var(--mantine-color-secondary-6) !important;
    }
}

.nodebase {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.inputtitle {
    position: absolute;
    min-width: 50%;
    top: calc(100% + 17px);
    pointer-events: none;
    border-radius: 4px;
    padding: var(--mantine-spacing-xs);
}

.intermediatetitle {
    position: absolute;
    min-width: 50%;
    top: calc(100% + 10px);
    pointer-events: none;
    border-radius: 4px;
    padding: var(--mantine-spacing-xs);
}

.tabslayoutlist {

    &::before {
        border: none !important;
    }

    & button {
        padding: 4px !important;
        padding-right: 0 !important;

        &:hover {
            border: none !important;
        }

        &[data-active="true"] {
            border: none !important;
        }
    }
}

.detailsscrolltabtabroot {
    margin-right: 1px !important;
    margin-left: 1px !important;
    min-height: 38px !important;
    color: var(--mantine-color-tertiary-2) !important;
    background-color: transparent !important;

    &:hover {
        color: var(--mantine-color-tertiary-9) !important;
        background-color: rgb(237, 245, 250) !important; /*var(--mantine-color-tertiary-outline-hover)*/
    }

    &[data='selected'] {

        color: var(--mantine-color-tertiary-9) !important;
        background-color: rgb(237, 245, 250) !important; /*var(--mantine-color-tertiary-outline-hover)*/

        & svg {
            fill: var(--mantine-color-tertiary-6) !important;
        }

        &:hover {
            /*color: var(--mantine-color-tertiary-6) !important;*/
            /*background-color: var(--mantine-color-primary-0) !important;*/

            & svg {
                fill: var(--mantine-color-tertiary-6) !important;
            }
        }
    }

    & svg {
        fill: var(--mantine-color-tertiary-0) !important;
    }
}

.scrollbargray {

    pointer-events: auto;

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='horizontal'],
    &[data-orientation='vertical'] {

        & .thumbgray {
            background-color: var(--mantine-color-gray-light-hover);
            z-index: 36 !important;

            &:before {
                background-color: var(--mantine-color-gray-light-hover);
            }
        }
    }
}

.scrollbarprimary {

    pointer-events: auto;

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='horizontal'],
    &[data-orientation='vertical'] {

        & .thumbprimary {
            background-color: var(--mantine-color-primary-light-hover);
            z-index: 36 !important;

            &:before {
                background-color: var(--mantine-color-primary-light-hover);
            }
        }
    }
}

.scrollbarsecondary {

    pointer-events: auto;

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='horizontal'],
    &[data-orientation='vertical'] {

        & .thumbsecondary {
            background-color: var(--mantine-color-secondary-light-hover);
            z-index: 36 !important;

            &:before {
                background-color: var(--mantine-color-secondary-light-hover);
            }
        }
    }
}

.scrollbartertiary {

    pointer-events: auto;

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='horizontal'],
    &[data-orientation='vertical'] {

        & .thumbtertiary {
            background-color: var(--mantine-color-tertiary-light-hover);
            z-index: 36 !important;

            &:before {
                background-color: var(--mantine-color-tertiary-light-hover);
            }
        }
    }
}

.corner {
    width: 0;
    height: 38px;
    background-color: transparent;
}

.simpleboxhoverableprimary {

    color:var(--mantine-color-primary-9);
    background-color: var(--mantine-color-primary-outline-hover);
    border-radius: 4px;
    padding: var(--mantine-spacing-md);
    cursor: pointer;

    &:hover {
        background-color: var(--mantine-color-primary-light-hover);
    }
}

.simpleboxhoverablesecondary {

    color:var(--mantine-color-secondary-9);
    background-color: var(--mantine-color-secondary-outline-hover);
    border-radius: 4px;
    padding: var(--mantine-spacing-md);
    cursor: pointer;

    &:hover {
        background-color: var(--mantine-color-secondary-light-hover);
    }
}

.simpleboxhoverabletertiary {

    color:var(--mantine-color-tertiary-9);
    background-color: var(--mantine-color-tertiary-outline-hover);
    border-radius: 4px;
    padding: var(--mantine-spacing-md);
    cursor: pointer;

    &:hover {
        background-color: var(--mantine-color-tertiary-light-hover);
    }
}

.simpleboxprimary {

    color:var(--mantine-color-primary-9);
    background-color: var(--mantine-color-primary-outline-hover);
    border-radius: 4px;
    padding: var(--mantine-spacing-md);
}

.simpleboxsecondary {

    color:var(--mantine-color-secondary-9);
    background-color: var(--mantine-color-secondary-outline-hover);
    border-radius: 4px;
    padding: var(--mantine-spacing-md);
}

.simpleboxtertiary {

    color:var(--mantine-color-tertiary-9);
    background-color: var(--mantine-color-tertiary-outline-hover);
    border-radius: 4px;
    padding: var(--mantine-spacing-md);
}

.projectstable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-secondary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;
        padding: 0 !important;
        padding-right: var(--mantine-spacing-md) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                color: var(--mantine-color-secondary-9) !important;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                    padding-right: var(--mantine-spacing-md) !important;
                }
            }
        }
    }
}

.newprojecttable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-secondary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;
        padding: 0 !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                color: var(--mantine-color-secondary-9) !important;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                    padding-right: var(--mantine-spacing-md) !important;
                }
            }
        }
    }
}

.detailscreationstable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-tertiary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            /*padding-right: var(--mantine-spacing-md) !important;*/
        }
    }

    & tbody {

        & tr {

            &.detailscreationstablecolored {
                & td {
                    font-weight: 700;
                    background-color: var(--mantine-color-tertiary-outline-hover) !important;
                    padding: 10px !important;
                    cursor: pointer;
                }

                &:hover {
                    & td {
                        opacity: 0.75;
                    }
                }
            }

            &.detailcreationstablesmall {
                & td {
                    padding-left: var(--mantine-spacing-xs) !important;
                    padding-right: var(--mantine-spacing-xs) !important;
                    padding-top: 4px !important;
                    padding-bottom: 4px !important;
                    cursor: pointer;
                }

                &:hover {
                    & td {
                        opacity: 0.75;
                    }
                }
            }

            & td {

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }

                & svg{
                    color: var(--mantine-color-tertiary-9) !important;
                }
            }
        }
    }
}

.detailsentitiestable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-tertiary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            padding-right: var(--mantine-spacing-md) !important;
        }
    }

    & tbody {

        & tr {

            &.detailsentitiestablecolored {
                & td {
                    font-weight: 700;
                    background-color: var(--mantine-color-tertiary-outline-hover) !important;
                    padding: 10px !important;
                    cursor: pointer;
                }

                /*&:hover {*/
                /*    & td {*/
                /*        opacity: 0.75;*/
                /*    }*/
                /*}*/
            }

            &.detailsentitiestablesmall {
                & td {
                    padding-left: var(--mantine-spacing-xs) !important;
                    padding-right: var(--mantine-spacing-xs) !important;
                    padding-top: 4px !important;
                    padding-bottom: 4px !important;
                    cursor: pointer;
                }

                /*&:hover {*/
                /*    & td {*/
                /*        opacity: 0.75;*/
                /*    }*/
                /*}*/
            }

            & td {

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }

            }
        }
    }
}

.detailsentitiestablepairings {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-tertiary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            padding-right: var(--mantine-spacing-md) !important;
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                cursor: pointer;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }

            /*&:hover {*/
            /*    & td {*/
            /*        opacity: 0.75;*/
            /*    }*/
            /*}*/
        }
    }
}

.detailsentitycategorypairingstable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-tertiary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            padding-right: var(--mantine-spacing-md) !important;
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                cursor: pointer;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }

            /*&:hover {*/
            /*    & td {*/
            /*        opacity: 0.75;*/
            /*    }*/
            /*}*/
        }
    }
}

.detailsentitypairingstable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-secondary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            padding-right: var(--mantine-spacing-md) !important;
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }
        }
    }
}

.detailsentitypairingsmolecularfilter {

    margin: calc(var(--mantine-spacing-md) * -1) !important;
    padding-top: 6px !important;
    padding-bottom: 26px !important;
    padding-left: 6px !important;
    padding-right: 8px !important;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: var(--mantine-color-secondary-light-hover) !important;
    }
}

.detailsentitytablenutrients {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        /*background-color: var(--mantine-color-primary-6) !important;*/
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            /*padding-right: var(--mantine-spacing-md) !important;*/
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }
        }
    }
}

.detailsentitytableingredients {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-primary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            /*padding-right: var(--mantine-spacing-md) !important;*/
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                cursor: pointer;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }
        }
    }
}

.detailsentityradio {
    border: 1px solid var(--mantine-color-primary-6) !important;
}

.detailsentitytext {

    &:hover {
        opacity: 0.75;
    }
}

.detailsentitytablepairings {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-primary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            padding-right: var(--mantine-spacing-md) !important;
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                cursor: pointer;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }

            /*&:hover {*/
            /*    & td {*/
            /*        opacity: 0.75;*/
            /*    }*/
            /*}*/
        }
    }
}

.detailsmoleculartable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-tertiary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            /*padding-right: var(--mantine-spacing-md) !important;*/
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                cursor: pointer;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }

            /*&:hover {*/
            /*    & td {*/
            /*        opacity: 0.75;*/
            /*    }*/
            /*}*/
        }
    }
}

.detailsmolecularcharttable {
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: var(--mantine-color-tertiary-6) !important;
        color: var(--mantine-color-white) !important;
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;

        &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }

        &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            padding-right: var(--mantine-spacing-md) !important;
        }
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                    padding-right: var(--mantine-spacing-md) !important;
                }
            }
        }
    }
}

.detailssarchinputprimary {

    color: var(--mantine-color-white) !important;
    border-color: transparent !important;
    background-color: transparent !important;
    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    height: 38px !important;

    &:focus {
        color: var(--mantine-color-white) !important;
        border-color: var(--mantine-color-primary-9) !important;
        background-color: var(--mantine-color-primary-9) !important;

        &::placeholder {
            color: var(--mantine-color-primary-2) !important;
            /*font-weight: 400 !important;*/
        }

        &::-ms-input-placeholder {
            color: var(--mantine-color-primary-2) !important;
            /*font-weight: 400 !important;*/
        }
    }

    &::placeholder {
        color: var(--mantine-color-white) !important;
        font-weight: 700 !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-white) !important;
        font-weight: 700 !important;
    }
}

.detailssarchinputsecondary {

    color: var(--mantine-color-white) !important;
    border-color: transparent !important;
    background-color: transparent !important;
    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    height: 38px !important;

    &:focus {
        color: var(--mantine-color-white) !important;
        border-color: var(--mantine-color-secondary-9) !important;
        background-color: var(--mantine-color-secondary-9) !important;

        &::placeholder {
            color: var(--mantine-color-secondary-2) !important;
            /*font-weight: 400 !important;*/
        }

        &::-ms-input-placeholder {
            color: var(--mantine-color-secondary-2) !important;
            /*font-weight: 400 !important;*/
        }
    }

    &::placeholder {
        color: var(--mantine-color-white) !important;
        font-weight: 700 !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-white) !important;
        font-weight: 700 !important;
    }
}

.detailssarchinputtertiary {

    color: var(--mantine-color-white) !important;
    border-color: transparent !important;
    background-color: transparent !important;
    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    height: 38px !important;

    &:focus {
        color: var(--mantine-color-white) !important;
        border-color: var(--mantine-color-tertiary-9) !important;
        background-color: var(--mantine-color-tertiary-9) !important;

        &::placeholder {
            color: var(--mantine-color-tertiary-2) !important;
            /*font-weight: 400 !important;*/
        }

        &::-ms-input-placeholder {
            color: var(--mantine-color-tertiary-2) !important;
            /*font-weight: 400 !important;*/
        }
    }

    &::placeholder {
        color: var(--mantine-color-white) !important;
        font-weight: 700 !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-white) !important;
        font-weight: 700 !important;
    }
}

.slidertrack {

    background-color: var(--mantine-color-secondary-7) !important;

    &::before {
        background-color: var(--mantine-color-secondary-0) !important;
        opacity: 0.35 !important;
    }
}

.rangeslidertrack {

    background-color: var(--mantine-color-secondary-light-hover) !important;

    &::before {
        background-color: var(--mantine-color-secondary-2) !important;
        opacity: 0.20 !important;
    }
}

.rangeslidermarklabel {
    /*color: var(--mantine-color-black) !important;*/
    color: var(--mantine-color-secondary-9) !important;
    font-size: var(--mantine-font-size-xs) !important;
    font-weight: 400;
}

.rangeslidermark {
    background-color: var(--mantine-color-white) !important;
    border: 1px solid var(--mantine-color-secondary-2) !important;
}

.detailspaginationcontrolprimary {

    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border: transparent !important;
    background-color: var(--mantine-color-primary-outline-hover) !important;
    color: var(--mantine-color-primary-9) !important;
    border-radius: 4px !important;

    &:hover {
        background-color: var(--mantine-color-primary-light-hover) !important;
    }

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;
        background-color: var(--mantine-color-primary-6) !important;
    }

    &[data-disabled="true"] {
        background-color: transparent !important;
    }
}

.detailspaginationdotsprimary {
    color: var(--mantine-color-primary-9) !important;
}

.detailspaginationcontrolsecondary {

    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border: transparent !important;
    background-color: var(--mantine-color-secondary-outline-hover) !important;
    color: var(--mantine-color-secondary-9) !important;
    border-radius: 4px !important;

    &:hover {
        background-color: var(--mantine-color-secondary-light-hover) !important;
    }

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;
        background-color: var(--mantine-color-secondary-6) !important;
    }

    &[data-disabled="true"] {
        background-color: transparent !important;
    }
}

.detailspaginationdotssecondary {
    color: var(--mantine-color-secondary-9) !important;
}

.detailspaginationcontroltertiary {

    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border: transparent !important;
    background-color: var(--mantine-color-tertiary-outline-hover) !important;
    color: var(--mantine-color-tertiary-9) !important;
    border-radius: 4px !important;

    &:hover {
        background-color: var(--mantine-color-tertiary-light-hover) !important;
    }

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;
        background-color: var(--mantine-color-tertiary-6) !important;
    }

    &[data-disabled="true"] {
        background-color: transparent !important;
    }
}

.detailspaginationdotstertiary {
    color: var(--mantine-color-tertiary-9) !important;
}

.defaultreactflowpanel {
    pointer-events: none !important;
}

.detailstab {

    & button {
        padding: 0 !important;

    }
}

.modalrootcreation {

}

.modalheadercreation {
    background-color: transparent !important;
}

.modalcontentcreation {
    background: linear-gradient(315deg, var(--mantine-color-tertiary-6) 0%, var(--mantine-color-tertiary-9) 75%) !important;
}

.modalinnercreation {

}

.modabodycreation {
    padding: 0 !important;
}


.modalheadertertiary {
    background-color: var(--mantine-color-tertiary-0) !important;
    padding: 0 !important;
    /*background: transparent !important;*/
}

.modalbodytertiary {
    padding-top: var(--mantine-spacing-lg) !important;
    padding-right: var(--mantine-spacing-md) !important;
    background-color: var(--mantine-color-tertiary-outline-hover) !important;
    color: var(--mantine-color-tertiary-9) !important;
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}


.modalroottransparent {
    background-color: transparent !important;
}

.modalroot {
    /*color: var(--mantine-color-secondary-9) !important;*/
}

.modalinner {
}

.modalheader {
    padding: 0 !important;
    background: transparent !important;
    /*background-color: var(--mantine-color-secondary-0) !important;*/
}

.modalcontent {
    background: linear-gradient(315deg, var(--mantine-color-secondary-7) 0%, var(--mantine-color-secondary-9) 75%) !important;
    /*background-color: var(--mantine-color-secondary-0) !important;*/
    /*background: transparent !important;*/
}

.modalbody {
    padding-top: var(--mantine-spacing-lg) !important;
    padding-right: var(--mantine-spacing-md) !important;
    /*background-color: var(--mantine-color-secondary-outline-hover) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}

.modalnumbercontrols {
    /*background-color: var(--mantine-color-secondary-6) !important;*/

}

.modalnumbercontrol {
    background-color: var(--mantine-color-secondary-light-hover) !important;
    color: var(--mantine-color-secondary-9) !important;
    border: 0 !important;

    &:hover {
        background-color: var(--mantine-color-secondary-6) !important;
        color: var(--mantine-color-white) !important;
    }
}

.modalinputpill {
    color: var(--mantine-color-secondary-9) !important;
    background-color: var(--mantine-color-secondary-light-hover) !important;
}

.modalinputgroupLabel {
    color: var(--mantine-color-secondary-9) !important;
    font-weight: 700 !important;
    opacity: 0.75 !important;

    &::after {
        display: none !important;
    }
}

.modalinputinputField {

    &::placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }
}

.modalinputoption {

    &[data-combobox-selected="true"] {
        background-color: var(--mantine-color-secondary-outline-hover) !important;
    }

    &:hover {
        background-color: var(--mantine-color-secondary-light-hover) !important;
    }
}

.modalinputoptionrendered {
}

.modalinputlabel {

    color: var(--mantine-color-white) !important;
    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    padding-bottom: var(--mantine-font-size-xs) !important;
}

.modalinputinput {

    color: var(--mantine-color-secondary-9) !important;
    border-color: var(--mantine-color-secondary-2) !important;
    /*background-color: var(--mantine-color-secondary-outline-hover) !important;*/
    height: 38px !important;

    &:focus {
        border-color: var(--mantine-color-secondary-6) !important;
    }

    &::placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }
}

@keyframes fadeBlink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
}

.blinkingstart {
    animation: fadeBlink 1s infinite ease-in-out;
}

.blinkingend {
    opacity: 1;
}

.homebackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: unset;
    background-image:
            radial-gradient(circle at 1px 1px, var(--mantine-color-primary-9) 1px, transparent 0),
            radial-gradient(circle at 20px 20px, var(--mantine-color-primary-9) 1px, transparent 0);
    background-size: 40px 40px;
    opacity: 0.4;
}






@keyframes hero-gradient-animation {
    0% {
        --c-0: hsla(314.010152284264, 86%, 55%, 1);
        --x-0: 90%;
        --y-0: 67%;
        --s-start-0: 3%;
        --s-end-0: 74%;
        --c-1: hsla(17.142857142857142, 100%, 56%, 1);
        --x-1: 90%;
        --y-1: 43%;
        --s-start-1: 15.839700289546327%;
        --s-end-1: 74%;
        --c-2: hsla(196.38655462184872, 100%, 46%, 1);
        --x-2: 64%;
        --y-2: 55%;
        --s-start-2: 3%;
        --s-end-2: 74%;
    }

    100% {
        --c-0: hsla(314.010152284264, 86%, 55%, 1);
        --x-0: 7%;
        --y-0: 71%;
        --s-start-0: 3%;
        --s-end-0: 74%;
        --c-1: hsla(17.142857142857142, 100%, 56%, 1);
        --x-1: 67%;
        --y-1: 43%;
        --s-start-1: 23.833027268737997%;
        --s-end-1: 74%;
        --c-2: hsla(196.38655462184872, 100%, 46%, 1);
        --x-2: 72%;
        --y-2: 59%;
        --s-start-2: 3%;
        --s-end-2: 74%;
    }
}

@property --c-0 {
    syntax: '<color>';
    inherits: false;
    initial-value: hsla(314.010152284264, 86%, 55%, 1)
}

@property --x-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 90%
}

@property --y-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 67%
}

@property --s-start-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 3%
}

@property --s-end-0 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 74%
}

@property --c-1 {
    syntax: '<color>';
    inherits: false;
    initial-value: hsla(17.142857142857142, 100%, 56%, 1)
}

@property --x-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 90%
}

@property --y-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 43%
}

@property --s-start-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 15.839700289546327%
}

@property --s-end-1 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 74%
}

@property --c-2 {
    syntax: '<color>';
    inherits: false;
    initial-value: hsla(196.38655462184872, 100%, 46%, 1)
}

@property --x-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 64%
}

@property --y-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 55%
}

@property --s-start-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 3%
}

@property --s-end-2 {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 74%
}

.animatedbackground {
    --c-0: hsla(314.010152284264, 86%, 55%, 1);
    --x-0: 90%;
    --y-0: 67%;
    --c-1: hsla(17.142857142857142, 100%, 56%, 1);
    --x-1: 90%;
    --y-1: 43%;
    --c-2: hsla(196.38655462184872, 100%, 46%, 1);
    --x-2: 64%;
    --y-2: 55%;
;
    background-color: hsla(314.010152284264, 86%, 55%, 1);
    background-image: radial-gradient(circle at var(--x-0) var(--y-0), var(--c-0) var(--s-start-0), transparent var(--s-end-0)), radial-gradient(circle at var(--x-1) var(--y-1), var(--c-1) var(--s-start-1), transparent var(--s-end-1)), radial-gradient(circle at var(--x-2) var(--y-2), var(--c-2) var(--s-start-2), transparent var(--s-end-2));
    animation: hero-gradient-animation 3s linear infinite alternate-reverse;
    background-blend-mode: normal, normal, screen;
}
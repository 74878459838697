.amazonlinkroot {

    &:hover {
        opacity: 1;
    }
}

.amazonlinkimage169root {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; /*Aspect ratio 16:9 (9 / 16 = 0.5625)*/

    & .amazonlinkimage169noimage {
        position: absolute;
        /*background-color: var(--mantine-color-white);*/
        /*opacity: 0.12;*/
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;

        & svg {
            /*color: var(--mantine-color-tertiary-6);*/
            margin-top: calc(25% / 2 * -1);
        }
    }

    & .amazonlinkimage169image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        filter: contrast(1.1) brightness(0.95);
    }

    & .amazonlinkimage169overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 0;

        /*& .amazonlinkimage169imagefoodfacts {*/
        /*    position: relative !important;*/
        /*    fill: var(--mantine-color-white);*/
        /*    z-index: 1 !important;*/
        /*}*/

        & .amazonlinkimage169title {
            position: relative !important;
            z-index: 1 !important;
        }
    }
}

.amazonlinkimage169righticon {
    position: absolute;
    top: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
}

.amazonlinkimage169withzoomicon {
    color: var(--mantine-color-white);
    padding-top: 10px !important;
    border-radius: var(--mantine-radius-default);
    background-color: var(--mantine-color-secondary-6);
    border: 1px solid var(--mantine-color-secondary-6);
    width: 38px !important;
    height: 38px !important;
}
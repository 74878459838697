.table {

    & tbody {

        & tr {
            height: 60px;


            & td {
                border-bottom: 2px solid var(--mantine-color-body);

                &:first-child {
                    border-top-left-radius: var(--mantine-radius-default);
                    border-bottom-left-radius: var(--mantine-radius-default);
                }

                &:last-child {
                    border-top-right-radius: var(--mantine-radius-default);
                    border-bottom-right-radius: var(--mantine-radius-default);
                }
            }
        }
    }
}
.icon {
    fill: var(--mantine-color-white);
    width: 28px;
    height: 38px;
}

.logo {
    fill: var(--mantine-color-white);
    height: 28px;
}

.logoFull {
    fill: var(--mantine-color-white);
    height: 32px;
    padding-top: 4px;
}
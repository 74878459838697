.recipecardtable {

    color: var(--mantine-color-white);

    & tbody {

        & tr {
            height: 30px !important;

            & td {
                padding-bottom: 0;
            }

            &:first-child {

                & td {
                    padding-top: var(--mantine-spacing-md);
                }
            }
        }
    }
}

.recipeimage169avatar {
    background-color: var(--mantine-color-tertiary-6);
}

.recipesubcomponentroot {
    position: relative !important;
    padding-top: var(--mantine-spacing-md) !important;
    padding-bottom: var(--mantine-spacing-md) !important;
}

.recipesubcomponentoverlay {
    position: absolute !important;
    z-index: -1 !important;
    border-radius: 8px !important;
    /*border: 1px solid var(--mantine-color-tertiary-outline-hover) !important;*/
}

.timelineitem {
    margin-top: var(--mantine-spacing-md) !important;

    &:first-child {
        margin-top: 0 !important;
    }

    &::before {
        border-color: var(--mantine-color-secondary-2) !important;
    }

    &[data-active="true"]::before {
        border-color: var(--mantine-color-secondary-6) !important;
    }
}

.timelineitembullet {
    color: var(--mantine-color-secondary-9) !important;
    font-weight: 700;
    border-radius: 5px !important;
    background-color: #faefed !important; /* Trasparent to color */
    border-color: #faefed !important;
    font-size: 14px !important;

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;
        background-color: var(--mantine-color-secondary-6) !important;
        border-color: var(--mantine-color-secondary-6) !important;
    }
}

.checkboxinput {
    border: 1px solid var(--mantine-color-secondary-6) !important;
}

.pillroot {
    color: var(--mantine-color-white) !important;
    background-color: rgba(255, 255, 255, 0.12) !important;
}
.menutarget {
    /*border: 2px solid var(--mantine-color-secondary-6) !important;*/
}

.login {
    cursor: pointer;
}

.anchor {
    color: var(--mantine-color-white)  !important;
    /*font-size: var(--mantine-font-size-sm) !important;*/
}

.modalroot {
    color: var(--mantine-color-tertiary-9) !important;
}

.modalinner {
}

.modalheader {
    background-color: var(--mantine-color-body) !important;
    padding: 0 !important;
    /*background: transparent !important;*/
}

.modalcontent {
    background-color: var(--mantine-color-body) !important;
    /*background: transparent !important;*/
}

.modalbody {
    padding-top: var(--mantine-spacing-lg) !important;
    padding-right: var(--mantine-spacing-md) !important;
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: calc(var(--mantine-z-index-app) * -1);
    color: var(--mantine-color-white);
    /*background-color: var(--mantine-color-primary-8);*/
    background-color: var(--mantine-color-primary-1);

    /*background-image: radial-gradient(circle at 1px 1px, var(--mantine-color-primary-7) 3px, transparent 0);*/
    /*background-size: calc(var(--mantine-custom-components-header-height) / 3) calc(var(--mantine-custom-components-header-height) / 3);*/
    background-image:
            radial-gradient(circle at 1px 1px, var(--mantine-color-primary-2) 1px, transparent 0),
            radial-gradient(circle at calc(var(--mantine-custom-components-header-height) / 4) calc(var(--mantine-custom-components-header-height) / 4), var(--mantine-color-primary-2) 1px, transparent 0);
    background-size: calc(var(--mantine-custom-components-header-height) / 2) calc(var(--mantine-custom-components-header-height) / 2);
}

.footermobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: calc(var(--mantine-z-index-app) * -1);
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-primary-6);
}

.footersvg {
    z-index: calc(var(--mantine-z-index-app) * -1);
    position: absolute;
    pointer-events: none;
    bottom: 0;
    right: 0;
    left: 0;
}

.logo {
    max-width: 300px;
    margin-top: -9px;

    @media (max-width: var(--mantine-breakpoint-sm)) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.description {
    margin-top: 5px;

    @media (max-width: var(--mantine-breakpoint-sm)) {
        margin-top: var(--mantine-spacing-xs);
        text-align: center;
    }
}

.groups {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: var(--mantine-breakpoint-sm)) {
        display: none;
    }
}

.wrapper {
    width: 160px;
}

.link {
    display: block;
    color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1));
    font-size: var(--mantine-font-size-sm);
    padding-top: 3px;
    padding-bottom: 3px;

    &:hover {
        text-decoration: underline;
    }
}

.title {
    font-size: var(--mantine-font-size-lg);
    font-weight: 700;
    margin-bottom: calc(var(--mantine-spacing-xs) / 2);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.footerLast {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: var(--mantine-spacing-xl);
}

.staticLink {
    padding-bottom: 6px !important;
    color: var(--mantine-color-white) !important;

    &:hover {
        color: var(--mantine-color-white) !important;
    }
}
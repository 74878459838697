/*body {*/
/*    margin: 0;*/
/*    !*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*!*/
/*    !*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*!*/
/*    !*sans-serif;*!*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/

/*}*/

body {
    letter-spacing: -.02rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--mantine-color-primary-6);
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;

    /*&:visited, &:hover, a:active {*/
    /*    color: inherit;*/
    /*}*/

    &:hover {
        opacity: 0.75;
    }
}

*:focus {
    outline: none !important;
}
.recipelinkroot {

    &:hover {
        opacity: 1;
    }
}

.recipelinkimage169root {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%; /*Aspect ratio 16:9 (9 / 16 = 0.5625)*/

    & .recipelinkimage169image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        filter: contrast(1.1) brightness(0.95);
    }

    & .recipelinkimage169overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        & .recipelinkimage169title {
            position: relative;
            z-index: 2;
        }
    }

    & .recipelinkimage169new {
        position: absolute;
        top: var(--mantine-spacing-md);
        left: var(--mantine-spacing-md);
    }

    & .recipelinkimage169avatar {
        position: absolute;
        top: var(--mantine-spacing-md);
        right: var(--mantine-spacing-md);
        background-color: var(--mantine-color-tertiary-6);
    }
}

.pillroot {
    color: var(--mantine-color-primary-9) !important;
    background-color: var(--mantine-color-primary-outline-hover) !important;
}
